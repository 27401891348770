// 默认分页配置
exports.DEFAULT_PAGINATION = {
  pageIndex: 1,
  pageSize: 20,
  showSizeChanger: true,
  total: 0,
  showTotal: total => `共 ${total} 条 `,
  showQuickJumper: true,
};

exports.QN_UPLOAD_DOMAIN = 'https://up.qbox.me';
// 七牛回显地址
exports.QN_CDN_DOMAIN = 'https://eweixin.lzzg365.com/'; // 天梯
exports.CSC_QN_CDN_DOMAIN = 'https://hweixin.sjwhjy.cn/'; // 中信建投天梯

//vip七牛上传;
exports.QN_UPLOAD_DOMAIN_VIP = 'https://up.qbox.me';
// vip七牛回显地址
exports.QN_CDN_DOMAIN_VIP = 'https://acqnunion.feierlaiedu.com/';
// 七牛视频地址
exports.QN_VIDEO_DOMAIN = 'https://video.feierlaiedu.com/';
// 七牛文件地址
exports.QN_CDN_KEY = 'image';
// 视频校验正则
exports.VIDEO_TYPE = '.(mp4|flv|avi|rmvb|wmv|mpg|rm|mpeg|mkv|MP4)$';
// 默认分页配置

exports.DOMAIN_NAME = '预发-公众号管理';

exports.STSTEM_NAME = '预发-公众号管理';
