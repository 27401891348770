// 因接口跨域，故无法拿到错误状态码
// const codeMessage = {
//   200: '服务器成功返回请求的数据。',
//   201: '新建或修改数据成功。',
//   202: '一个请求已经进入后台排队（异步任务）。',
//   204: '删除数据成功。',
//   400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
//   401: '用户没有权限（令牌、用户名、密码错误）。',
//   403: '用户得到授权，但是访问是被禁止的。',
//   404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
//   406: '请求的格式不可得。',
//   410: '请求的资源被永久删除，且不会再得到的。',
//   422: '当创建一个对象时，发生一个验证错误。',
//   500: '服务器发生错误，请检查服务器。',
//   502: '网关错误。',
//   503: '服务不可用，服务器暂时过载或维护。',
//   504: '网关超时。',
// };
import md5 from 'js-md5';
import { Base64 } from 'js-base64';
import { history } from 'umi';
import { notification } from 'antd';
import axios from 'axios';
import { urlQueryToObj } from './utils';

const isTest = !(APP_ENV === 'prod' || APP_ENV === 'pre'); // eslint-disable-line no-undef
const baseUrl = BASE_API; // eslint-disable-line no-undef
const baseTiantiUrl = BASE_TIANTI_API; // eslint-disable-line no-undef 
const reqSecret = REQUEST_SECRET; // eslint-disable-line no-undef
const salt = '';

class HttpRequest {
  constructor() {
    this.options = {
      method: 'post',
      url: '',
      data: {},
      errToast: true,
    };
    this.token = localStorage.getItem('token') || '';
    this.platformId = localStorage.getItem('platformId') || '';
    this.userInfo = JSON.parse(localStorage.getItem('userInfo')) || {};
    this.checkToken();
  }

  checkToken() {
    // 如果没有token、中断请求并跳转到login
    const pathname = ['/login'];
    const thisPathname = window.location.pathname;
    if (this.token === '' && !pathname.includes(thisPathname)) {
      return new Promise((resolve, reject) => {
        reject(
          history.replace({
            pathname: '/login',
            query: {
              redirect: thisPathname,
            },
          }),
        );
      });
    }
    return '';
  }

  sign(options, timestamp) {
    // body参数
    const body = JSON.parse(JSON.stringify(options.data));
    // query参数
    const query = urlQueryToObj(options.url);
    // 合并
    const obj = Object.assign(query, body);
    // 添加密钥
    obj.secret = reqSecret;
    let str = Object.keys(obj)
      .sort()
      .reduce(
        (sum, next) =>
          sum +
          next +
          '=' +
          (typeof obj[next] !== 'string' ? JSON.stringify(obj[next]) : obj[next]) +
          '&',
        '',
      )
      // .replace(/\s/g, '');
    // 旧签名规则
    // const sign = md5(Base64.encode(String(timestamp)) + this.token + salt + str);
    str = str.substr(0, str.length - 1);
    const sign = md5(str);
    return sign;
  }

  consoleErr(res) {
    if (!isTest) return;
    // 控制台打印报错信息
    // const body = {
    //   ...this.options.body,
    //   timestamp: this.options.timestamp,
    // };
    //  console.error(`请求URL: ${this.options.url}`);
    // console.error('请求参数：');
    //   console.error(`请求参数：${JSON.stringify(body, null, 2)}`);
    // console.error('接口返回：');
    console.error(`接口报错${this.options.url}：${JSON.stringify(res, null, 2)}`);
  }

  handleResponse(res) {
    if (
      this.options.body &&
      this.options.body.config &&
      this.options.body.config.responseType === 'arraybuffer'
    ) {
      let enc = new TextDecoder('utf-8');
      const newRes = JSON.parse(enc.decode(new Uint8Array(res))); //转化成json对象
      //   if (newRes.code !== 0){
      res = newRes;
      //   }
    }
    // token失效
    if (res.code === 10004) {
      notification.error({
        message: '登录已过期，请重新登录',
      });
      localStorage.removeItem('token');
      localStorage.removeItem('userInfo');
      localStorage.removeItem('platformId');
      return Promise.reject(
        history.replace({
          pathname: '/login',
          query: {
            redirect: window.location.pathname,
          },
        }),
      );
    }

    // 无系统权限
    if (res.code === 100059 || res.code === 100049) {
      return Promise.reject(
        history.replace({
          pathname: '/403',
        }),
      );
    }

    if (res.code !== 0) {
      if (res && this.options.errToast) {
        window.setTimeout(
          notification.error({
            message: res.message || '操作失败！',
            description: res.exception,
          }),
          0,
        );
        this.consoleErr(res, this.options);
      }
      return Promise.reject(res);
    }
    return Promise.resolve(res);
  }

  // 请求拦截
  interceptors(instance) {
    // 添加响应拦截器
    instance.interceptors.response.use(
      res => {
        if (res.config.responseType == 'blob') {
          return Promise.resolve(res);
        }
        if (res.headers['content-type'] && res.headers['content-type'].match('excel')) {
          return Promise.resolve(res);
        }
        const { data } = res;
        return this.handleResponse(data);
      },
      error => Promise.reject(error),
    );
  }

  // 创建实例
  create() {
    const timestamp = ~~(+new Date() / 1000); // eslint-disable-line no-bitwise

    const conf = {
      // 增加两个拼接路径
      baseURL: this.options.ownUrl ? baseTiantiUrl :baseUrl,
      timeout: this.options.timeout || 30000,
      ...this.options.data.config,
      headers: {
        timestamp,
        signature: this.sign(this.options, timestamp),
        token: this.token,
        platformId: this.platformId,
        partnerCode: localStorage.getItem('partnerCode') || '',
        accountId: this.userInfo.id || '',
        ladderMenuRoute: window.__ladderMenuRoute || '',
        ladderMenuRouteName: encodeURIComponent(window.__ladderMenuRouteName || '')
      },
    };
    // 上传 multipart/form-data 类型数据
    if (this.options.type === 'form-data') {
      conf.headers['Content-Type'] = 'multipart/form-data';
    }
    return axios.create(conf);
  }

  // 请求实例
  request(options) {
    // 合并请求参数
    this.options = {
      ...this.options,
      ...options,
      data: {
        ...this.options.data,
        ...options.body,
      },
    };

    // FormData类型不支持解构
    if (options.type === 'form-data') {
      this.options.data = options.body;
    }

    // 不需要解构
    if (options.type === 'normal') {
      this.options.data = options.body;
    }

    const instance = this.create();
    this.interceptors(instance, options.url);
    return instance(this.options);
  }
}

function http(obj) {
  //   const vm = this;

  return new HttpRequest().request(obj);
}

export default http;
