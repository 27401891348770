// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/home/www/.jenkins/workspace/pre-wechat-config-admin-h5/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@ant-design/pro-layout/es/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "redirect": "/app/block",
    "exact": true
  },
  {
    "path": "/app",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__RouteFilter' */'/home/www/.jenkins/workspace/pre-wechat-config-admin-h5/src/layouts/RouteFilter'), loading: LoadingComponent}),
    "Routes": [
      "src/pages/Authorized"
    ],
    "authority": [
      "admin",
      "user"
    ],
    "routes": [
      {
        "path": "/app/block",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__block' */'/home/www/.jenkins/workspace/pre-wechat-config-admin-h5/src/pages/block'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "remark": "公众号中心",
        "path": "/app/account-center",
        "routes": [
          {
            "remark": "公众号管理",
            "path": "/app/account-center/account",
            "name": "accountCenterAccountManage",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accountCenter__accountManage' */'/home/www/.jenkins/workspace/pre-wechat-config-admin-h5/src/pages/accountCenter/accountManage'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "remark": "菜单管理",
            "path": "/app/account-center/menu",
            "name": "accountCenterMenuManage",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accountCenter__menuManage' */'/home/www/.jenkins/workspace/pre-wechat-config-admin-h5/src/pages/accountCenter/menuManage'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "remark": "事件中心",
        "path": "/app/event-center",
        "routes": [
          {
            "remark": "事件管理",
            "path": "/app/event-center/event",
            "name": "eventCenterEventManage",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__eventCenter__eventManage' */'/home/www/.jenkins/workspace/pre-wechat-config-admin-h5/src/pages/eventCenter/eventManage'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "remark": "策略库",
            "path": "/app/event-center/policy",
            "name": "eventCenterPolicyLibrary",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__eventCenter__policyLibrary' */'/home/www/.jenkins/workspace/pre-wechat-config-admin-h5/src/pages/eventCenter/policyLibrary'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "remark": "小程序管理",
        "path": "/app/mini-center",
        "routes": [
          {
            "remark": "key文件管理",
            "path": "/app/mini-center/keyFileManage",
            "name": "miniCenterKeyFileManage",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__miniCenter__keyFileManage' */'/home/www/.jenkins/workspace/pre-wechat-config-admin-h5/src/pages/miniCenter/keyFileManage'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "remark": "小程序管理",
            "path": "/app/mini-center/miniManage",
            "name": "miniCenterMiniManage",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__miniCenter__miniManage' */'/home/www/.jenkins/workspace/pre-wechat-config-admin-h5/src/pages/miniCenter/miniManage'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "remark": "小程序草稿、模板管理",
            "path": "/app/mini-center/draftManage",
            "name": "miniCenterDraftManage",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__miniCenter__draft' */'/home/www/.jenkins/workspace/pre-wechat-config-admin-h5/src/pages/miniCenter/draft'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "remark": "发版管理",
            "path": "/app/mini-center/releaseManage",
            "name": "miniCenterReleaseManage",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__miniCenter__releaseManage' */'/home/www/.jenkins/workspace/pre-wechat-config-admin-h5/src/pages/miniCenter/releaseManage'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "remark": "小程序数据统计",
            "path": "/app/mini-center/dataCount",
            "name": "miniCenterDataCount",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__miniCenter__dataCount' */'/home/www/.jenkins/workspace/pre-wechat-config-admin-h5/src/pages/miniCenter/dataCount'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "remark": "小程序批量任务",
            "path": "/app/mini-center/batch",
            "name": "miniCenterBatch",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__miniCenter__batch' */'/home/www/.jenkins/workspace/pre-wechat-config-admin-h5/src/pages/miniCenter/batch'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "remark": "授权中心",
        "path": "/app/authorization-center",
        "routes": [
          {
            "remark": "授权管理",
            "path": "/app/authorization-center/index",
            "name": "authorizationCenterAuthorizationManage",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__authorizationCenter__authorizationManage' */'/home/www/.jenkins/workspace/pre-wechat-config-admin-h5/src/pages/authorizationCenter/authorizationManage'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "remark": "配置中心",
        "path": "/app/configuration-center",
        "routes": [
          {
            "remark": "sku配置",
            "path": "/app/configuration-center/sku",
            "name": "configurationCenterSku",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__configurationCenter__sku' */'/home/www/.jenkins/workspace/pre-wechat-config-admin-h5/src/pages/configurationCenter/sku'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "remark": "开放平台配置",
            "path": "/app/configuration-center/open",
            "name": "configurationCenterOpenFlatform",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__configurationCenter__openflatform' */'/home/www/.jenkins/workspace/pre-wechat-config-admin-h5/src/pages/configurationCenter/openflatform'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "remark": "回调配置",
            "path": "/app/configuration-center/callBack",
            "name": "configurationCenterCallBack",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__configurationCenter__callBack' */'/home/www/.jenkins/workspace/pre-wechat-config-admin-h5/src/pages/configurationCenter/callBack'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "remark": "标签配置",
            "path": "/app/configuration-center/tagConfig",
            "name": "configurationCenterTagConfig",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__configurationCenter__tagConfig' */'/home/www/.jenkins/workspace/pre-wechat-config-admin-h5/src/pages/configurationCenter/tagConfig'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "remark": "商户中心",
        "path": "/app/merchant-center",
        "routes": [
          {
            "remark": "商户配置",
            "path": "/app/merchant-center/merchant",
            "name": "merchantCenterMerchant",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__merchantCenter__merchant' */'/home/www/.jenkins/workspace/pre-wechat-config-admin-h5/src/pages/merchantCenter/merchant'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "remark": "系统配置",
            "path": "/app/merchant-center/department",
            "name": "merchantCenterDepartment",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__merchantCenter__department' */'/home/www/.jenkins/workspace/pre-wechat-config-admin-h5/src/pages/merchantCenter/department'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "remark": "数据中心",
        "path": "/app/data-center",
        "routes": [
          {
            "remark": "数据总览",
            "path": "/app/data-center/overview",
            "name": "dataCenterOverview",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__dataCenter__overview' */'/home/www/.jenkins/workspace/pre-wechat-config-admin-h5/src/pages/dataCenter/overview'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "remark": "订阅数据",
            "path": "/app/data-center/subscription",
            "name": "dataCenterSubscription",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__dataCenter__subscription' */'/home/www/.jenkins/workspace/pre-wechat-config-admin-h5/src/pages/dataCenter/subscription'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "remark": "操作日志",
            "path": "/app/data-center/operatelog",
            "name": "dataCenterOperatelog",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__dataCenter__operateLog' */'/home/www/.jenkins/workspace/pre-wechat-config-admin-h5/src/pages/dataCenter/operateLog'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "remark": "来源详情",
            "path": "/app/data-center/sourceDetail",
            "name": "sourceDetail",
            "hideInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__dataCenter__subscription__sourceDetail' */'/home/www/.jenkins/workspace/pre-wechat-config-admin-h5/src/pages/dataCenter/subscription/sourceDetail'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "remark": "菜单数据",
            "path": "/app/data-center/menu",
            "name": "dataCenterMenu",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__dataCenter__menu' */'/home/www/.jenkins/workspace/pre-wechat-config-admin-h5/src/pages/dataCenter/menu'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "remark": "小程序数据池",
            "path": "/app/data-center/miniProgramDataPool",
            "name": "miniProgramDataPool",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__dataCenter__miniProgramDataPool' */'/home/www/.jenkins/workspace/pre-wechat-config-admin-h5/src/pages/dataCenter/miniProgramDataPool'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "remark": "微信开放平台信息",
            "path": "/app/data-center/openPlatformDataBoard",
            "name": "openPlatformDataBoard",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__dataCenter__openPlatformDataBoard' */'/home/www/.jenkins/workspace/pre-wechat-config-admin-h5/src/pages/dataCenter/openPlatformDataBoard'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/home/www/.jenkins/workspace/pre-wechat-config-admin-h5/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "path": "/login",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__UserLayout' */'/home/www/.jenkins/workspace/pre-wechat-config-admin-h5/src/layouts/UserLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "name": "login",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__login' */'/home/www/.jenkins/workspace/pre-wechat-config-admin-h5/src/pages/login'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/home/www/.jenkins/workspace/pre-wechat-config-admin-h5/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "path": "/403",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__403' */'/home/www/.jenkins/workspace/pre-wechat-config-admin-h5/src/pages/403'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/home/www/.jenkins/workspace/pre-wechat-config-admin-h5/src/pages/404'), loading: LoadingComponent}),
    "exact": true
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
