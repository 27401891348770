import { Effect, ImmerReducer, Reducer, Subscription } from 'umi';
import { getMerchantList as getMerchantApi } from '@/services/merchantCenter';
export interface merChantType {
    id: string;
    code: string;
    name: string;
    ossUrl?: string;
}

export interface merchantState {
    merchantList: merChantType[]; // 商户列表
    selectMerchant: merChantType | null; // 当前商户类别
    showMerchant: boolean; // 是否展示商户分类
}

export interface merchantModelType {
    namespace: 'merchant';
    state: merchantState;
    effects: {
        getMerchant: Effect;
    };
    reducers: {
        save: Reducer<merchantState>;
    };
    subscriptions: { setup: Subscription };
}

const IndexModel: merchantModelType = {
    namespace: 'merchant',

    state: {
        merchantList: [],
        selectMerchant: null,
        showMerchant: false,
    },

    effects: {
        *getMerchant({ payload }, { call, put, select }) {
            const data = yield call(getMerchantApi, {
                pageNo: 1,
                pageSize: 500,
            });

            // 获取merchantList，根据seesion去设置selectMerchant
            const filterSelect = data.data.dataList.filter(
                (item: { code: string | null }) => item.code == sessionStorage.getItem('merchantCode'),
            );
            const selectMerchant = filterSelect.length ? filterSelect[0] : data.data.dataList[0];
            yield put({
                type: 'save',
                payload: {
                    merchantList: data.data.dataList,
                    selectMerchant: selectMerchant,
                },
            });
        },
    },
    reducers: {
        save(state, { payload }) {
            // 设置selectMerchant， 更新seesion
            if (payload.selectMerchant) {
                sessionStorage.setItem('merchantCode', payload.selectMerchant.code);
            }
            return {
                ...state,
                ...payload,
            };
        },
    },
    subscriptions: {
        setup({ dispatch, history }) {
            const paths = ['merchant-center'];
            history.listen(({ pathname }) => {
                window.__ladderMenuRoute = pathname;
                dispatch({
                    type: 'save',
                    payload: {
                        showMerchant: !paths.some(item => pathname.includes(item)),
                    },
                });
            });
        },
    },
};

export default IndexModel;
