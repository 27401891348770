import { AnyAction, Reducer } from 'redux';
import { EffectsCommandMap } from 'dva';
import { routerRedux } from 'dva/router';
import { ttLogin } from '../services/login';
import { getPageQuery, setLoginInfo } from '../utils/utils';
// @ts-ignore
const isTest = APP_ENV !== 'prod';
const ladderUrl = isTest ? 'https://testceab.qn76.cn' : 'https://smartladder.lzzg365.com';

export interface StateType {
    status?: 'ok' | 'error';
    userInfo?: object;
    currentAuthority?: 'user' | 'guest' | 'admin';
    hasPhone?: boolean;
}

export type Effect = (
    action: AnyAction,
    effects: EffectsCommandMap & { select: <T>(func: (state: StateType) => T) => T },
) => void;

export interface ModelType {
    namespace: string;
    state: StateType;
    effects: {
        ttLogin?: Effect;
        logout: Effect;
    };
    reducers: {
        changeLoginStatus: Reducer<StateType>;
    };
}

const Model: ModelType = {
    namespace: 'login',

    state: {
        userInfo: {},
    },

    effects: {
        *ttLogin({ payload }, { call, put }) {
            localStorage.setItem('token', payload.token);
            localStorage.setItem('platformId', payload.platformId);

            let response = { status: '', code: null, data: { mobile: '' } };

            // 中信建投
            if (payload.partnerCode) {
                localStorage.setItem('partnerCode', payload.partnerCode);
                response.status = 'ok';
            } else {
                // 自己天梯
                try {
                    delete payload.partnerCode;
                    response = yield call(ttLogin, payload);
                    response.status = response.code === 0 ? 'ok' : 'error';
                } catch (error) {
                    response.status = 'error';
                }
            }
            // Login successfully
            if (response.status === 'ok') {
                // 成功时存储用户信息
                yield put({
                    type: 'changeLoginStatus',
                    payload: response,
                });
                // const urlParams = new URL(window.location.href);
                const params = getPageQuery();
                const { redirect } = params as { redirect: string };
                yield put(routerRedux.replace(redirect || '/'));
            }
        },

        logout() {
            localStorage.removeItem('token');
            localStorage.removeItem('platformId');
            localStorage.removeItem('menu');
            localStorage.removeItem('userInfo');
            window.location.href = ladderUrl;
        },
    },

    reducers: {
        changeLoginStatus(state, { payload }) {
            if (payload.status === 'ok') setLoginInfo(payload.data);
            return {
                ...state,
                userInfo: payload.data,
            };
        },
    },
};

export default Model;
