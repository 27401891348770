import request from '@/utils/request';

// --------------------------------------------------商户配置-------------------------------------------------

// 获取商户配置列表
export async function getMerchantList(body: any) {
    return request({ url: '/partner/list', body });
}

// 新增商户配置
export async function addMerchant(body: any) {
    return request({ url: '/partner/save', body });
}

// --------------------------------------------------系统配置-------------------------------------------------

// 获取系统配置列表
export async function getDepartmentList(body: any) {
    return request({ url: '/sys/list', body });
}

// 新增系统配置
export async function addDepartment(body: any) {
    return request({ url: '/sys/save', body });
}

// 编辑系统配置
export async function editDepartment(body: any) {
    return request({ url: '/sys/updateByCode', body });
}

// 获取所有系统配置
export async function getAllDepartmentList() {
    return request({ method: 'GET', url: '/sys/listAll' });
}
