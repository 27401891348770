import { parse } from 'qs';
import { notification } from 'antd';
import { Column } from '@/types/common';
import { getDvaApp } from 'umi';
import project from './utils.project';
import { func } from 'prop-types';

function getPageQuery() {
    return parse(window.location.href.split('?')[1]);
}

function setLoginInfo(userInfo: any) {
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
}

/* eslint no-useless-escape:0 import/prefer-default-export:0 */
const reg =
    /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

const isUrl = (path: string): boolean => reg.test(path);

const isAntDesignPro = (): boolean => false;

// 给官方演示站点用，用于关闭真实开发环境不需要使用的特性
const isAntDesignProOrDev = (): boolean => {
    const { NODE_ENV } = process.env;
    if (NODE_ENV === 'development') {
        return true;
    }
    return isAntDesignPro();
};

// 成功弹窗
const success = function (text = '操作成功') {
    notification.success({
        key: 'success',
        message: text,
    });
};

// 失败弹窗
const warning = function (text = '服务器异常') {
    notification.warning({
        message: text,
    });
};

// 警告弹窗
const error = function (text = '服务器异常') {
    notification.error({
        message: text,
    });
};

// 金币：分-> 元
const handleRMB = (num: number) => (num ? (num / 100).toFixed(2) : '-');

// 金额：分->元(千分位)
function addThousandth(num: string) {
    return `${(parseInt(num) / 100).toFixed(2)}`.replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,');
}

function getBase64(file: any) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

// 随机字符串
function randomString(length: number) {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = length; i > 0; ) {
        result += chars[Math.floor(Math.random() * chars.length)];
        i -= 1;
    }
    return result;
}

/**
 * 判断是否为空对象
 */
function isEmptyObject(obj: Object) {
    for (const key in obj) {
        return false;
    }
    return true;
}

// 为每个元素增加空占位符 '-'
const columnFilter = (column: Column[], text: string) => {
    let newColumn = [];
    newColumn = column.map(item => {
        if (!item.render) {
            item.render = (val: any) => {
                if (typeof val === 'string') {
                    return val && val.replace(/\s/g, '') ? val : '-';
                }
                if (val === null || val === undefined) {
                    return '-';
                }
                return val;
            };
        }
        return item;
    });
    if (text !== 'none') {
        newColumn.unshift({
            title: text || '#',
            key: 'indexOrder',
            className: 'indexOrder',
            render: (a: any, b: any, c: any) => c + 1,
        });
    }
    return newColumn;
};

interface Status {
    value: string | number;
    label: string;
}
// 为每个元素增加空占位符 '-'
const handleStatusMap = (map: Status[], val: any) => {
    const item = map.find(i => i.value === val);
    return (item && item.label) || '未知类型';
};

// 复制
function copy(message: string) {
    const input = document.createElement('input');
    input.value = message;
    // input.style.height = '0px';
    document.body.appendChild(input);
    input.select();
    input.setSelectionRange(0, input.value.length);
    document.execCommand('Copy');
    document.body.removeChild(input);
}
// 下载图片
function getImageBlob(url: string) {
    const xhr = new XMLHttpRequest();
    xhr.open('get', url, true);
    xhr.responseType = 'blob';
    // eslint-disable-next-line func-names
    xhr.onload = function () {
        if (this.status === 200) {
            // 这里面可以直接通过URL的api将其转换，然后赋值给img.src
            // 也可以使用下面的preView方法将其转换成base64之后再赋值
            const src = URL.createObjectURL(this.response);
            imgToBase64(src);
        }
    };
    xhr.send();
}

/**
 * 下载地址资源到本地
 * @param {String} url
 * @param {String} filename
 */
function exportExcelForUrl(url: string, filename: string) {
    if (!url) {
        return;
    }
    const a = document.createElement('a');
    // let newUrl = url.replace('http://', 'https://')
    a.href = url;
    a.download = filename;
    // a.target = "_blank"
    a.click();
    window.URL.revokeObjectURL(url);
}

function imgToBase64(src: string) {
    const img = new Image();
    img.src = src;
    img.setAttribute('crossorigin', ' *');
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    img.onload = () => {
        canvas.height = img.height;
        canvas.width = img.width;
        if (ctx) ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        downloadImg(dataURL);
    };
}
function downloadImg(url: string) {
    const qrLinkEl = document.createElement('a');
    qrLinkEl.setAttribute('href', url);
    qrLinkEl.setAttribute('download', 'qr.png');
    qrLinkEl.click();
    qrLinkEl.removeAttribute('click');
}

// 导出stream表格
function exportExcel(stream: any, filename: any) {
    const a = document.createElement('a');
    const blob = new Blob([stream], { type: 'application/vnd.ms-excel' });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
}

/**
 * 转化时间格式
 * 2019-08-29T02:15:08.000+0000转化为2019-08-29 02:15:08
 */

function timeFormat(time: { substr: (arg0: number, arg1: number) => string | number | Date }) {
    const d = new Date(time.substr(0, 19)); // 加入substr(0, 19)处理兼容ios报错NAN
    const year = d.getFullYear();
    const month = d.getMonth() + 1;
    const day = d.getDate();

    const hh = d.getHours();
    const mm = d.getMinutes();
    const ss = d.getSeconds();

    let clock = `${year}-`;

    if (month < 10) {
        clock += '0';
    }

    clock += `${month}-`;

    if (day < 10) clock += '0';

    clock += `${day} `;

    if (hh < 10) clock += '0';

    clock += `${hh}:`;
    if (mm < 10) clock += '0';
    clock += `${mm}:`;

    if (ss < 10) clock += '0';
    clock += ss;
    return clock;
}

/**
 * 将时间转化为时间戳
 * 将2019-08-29 02:15:08转化为 时间戳
 */

function timestampFormat(time: { replace: (arg0: RegExp, arg1: string) => void }) {
    const thisTime = time.replace(/-/g, '/');
    // @ts-ignore
    const timestamp = new Date(thisTime);
    const timer = timestamp.getTime();
    return timer;
}

function isMobile(s: string) {
    return /^1[0-9]{10}$/.test(s);
}

//
function getJsonLength(json: any) {
    let jsonLength = 0;
    for (let i in json) {
        jsonLength++;
    }
    return jsonLength;
}

/*
1、移除所有空格
2、移除两边空格
*/
function trim(str: string, type: number) {
    type = type || 2;
    switch (type) {
        case 1:
            return str.replace(/\s+/g, '');
        case 2:
            return str.replace(/(^\s*)|(\s*$)/g, '');
        case 3:
            return str.replace(/(^\s*)/g, '');
        case 4:
            return str.replace(/(\s*$)/g, '');
        default:
            return str;
    }
}
/**
* @description 时间戳转化成指定格式的日期格式
* @param {number} timestamp 时间戳
* @param {string} format "y-m-d h:i:s" 年-月-日 时:分:秒，可任意组合
如下 ："y-m-d h:i" 年-月-日 时:分
// dateFormat(1610348504531)
*/
const dateFormat = (timestamp: number, format: string) => {
    const t = new Date(timestamp);
    // 日期格式
    const year = t.getFullYear();
    // 由于 getMonth 返回值会比正常月份小 1
    const month = (t.getMonth() + 1).toString().padStart(2, '0');
    const day = t.getDate().toString().padStart(2, '0');
    const hours = t.getHours().toString().padStart(2, '0');
    const minutes = t.getMinutes().toString().padStart(2, '0');
    const seconds = t.getSeconds().toString().padStart(2, '0');
    const hash = {
        y: year,
        m: month,
        d: day,
        h: hours,
        i: minutes,
        s: seconds,
    };
    return format.replace(/\w/g, o => {
        return hash[o];
    });
};

/**
 * 判断一个字符串的值是否是个数字
 * @param val
 */
function isNumber(val: any) {
    let regNeg = /^[0-9]+.?[0-9]*$/;
    if (regNeg.test(val)) {
        return true;
    } else {
        return false;
    }
}

interface SelectArr {
    value: string | number;
    label: string;
}

function getSelectLabel(arr: SelectArr[], val: any) {
    const newArr = arr.filter(item => item.value === val);
    if (newArr.length > 0) {
        return newArr[0].label;
    } else {
        return '-';
    }
}

// 获取相应的天数
function getCorrectDay(day: any) {
    const today = new Date();
    const targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
    today.setTime(targetday_milliseconds);
    const tYear = today.getFullYear();
    let tMonth = today.getMonth();
    let tDate = today.getDate();
    tMonth = getCorrectMonth(tMonth + 1);
    tDate = getCorrectMonth(tDate);
    return `${tYear}-${tMonth}-${tDate}`;
}

// 获取月
function getCorrectMonth(month: any) {
    let m = month;
    if (month.toString().length == 1) {
        m = `0${month}`;
    }
    return m;
}

// 特殊字符处理
function stripscript(value: string) {
    let pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？%～¥]");
    let rs = '';
    for (let i = 0; i < value.length; i++) {
        rs = rs + value.substr(i, 1).replace(pattern, '');
    }
    return rs;
}

// 校验微信，支付宝流水
function testOrderNum(type: number, timeFormat: string, orderNum: string) {
    // type 19微信 29支付宝
    // timeFormat YYYY-MM-DD HH:mm
    const timeStr = timeFormat.substr(0, 10).split('-').join(''); // YYYYMMDD
    const orderStr = orderNum.substr(type == 19 ? 10 : 0, 8); // 支付宝截取前8位 微信截取11-18位
    return timeStr === orderStr;
}

/**
 * @param {String} text 需要脱敏的字段
 * @description 将字段脱敏
 */
function setMobileStar(txt: any) {
    if (!txt) {
        return '';
    }
    const starNum = txt.length - 7;
    let starText = '****';
    if (starNum > 0) {
        starText = '';
        for (let i = 0; i < starNum; i++) {
            starText = `${starText}*`;
        }
    }
    txt = txt.substring(0, 3) + starText + txt.substring(txt.length - 4);
    return txt;
}

/**
 * @param {String} text 取手机号最后4位
 * @description 将字段脱敏
 */
function setMobileEnd4(txt: any) {
    if (!txt) {
        return '';
    }
    txt = txt.substring(txt.length - 4);
    return txt;
}

function deepClone(source: any) {
    if (!source && typeof source !== 'object') {
        throw new Error('error arguments');
    }
    const targetObj = source.constructor === Array ? [] : {};
    Object.keys(source).forEach(keys => {
        if (source[keys] && typeof source[keys] === 'object') {
            targetObj[keys] = deepClone(source[keys]);
        } else {
            targetObj[keys] = source[keys];
        }
    });
    return targetObj;
}

/**
 * @param {String} variable url参数名
 * @description 获取url 参数对应的值
 */
function getQueryVariable(variable: any) {
    let query = window.location.search.substring(1);
    let vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split('=');
        if (pair[0] == variable) {
            return pair[1];
        }
    }
    return false;
}

// 根据商户签获取前缀
function getMerchantUrl() {
    return getDvaApp()._models.filter((item: { namespace: string }) => item.namespace === 'merchant')[0].state
        .selectMerchant.merchantBase;
}

// url参数转为对象
function urlQueryToObj(str: string) {
    let obj = new Object();
    let str1 = str.split('?');
    if (!str1[1]) return obj;
    let str2 = str1[1].split('&');
    for (let i = 0; i < str2.length; i++) {
        let str3 = str2[i].split('=');
        obj[str3[0]] = str3[1];
    }
    return obj;
}

// 增加序号
const listAddOrderNumber = (listArr: { forEach: (arg0: (item: any, index: number) => void) => void }) => {
    listArr.forEach((item: { orderNumber: number }, index: number) => {
        item.orderNumber = index + 1;
    });
};

/**
 * 获取指定日期再往前或者往后推固定天数后的日期
 * parameter: dateParameter // 定义开始日期
 * parameter: num // 定义往前或者往后推的天数
 * parameter: along // 往期推固定天数还是往后推固定天数 默认 false 往前推固定天数
 */
function addByTransDate(dateParameter: string, num: number, along = false) {
    let translateDate = '';
    let dateString = '';
    let monthString = '';
    let dayString = '';
    translateDate = dateParameter.replace('-', '/').replace('-', '/');
    let newDate: any = new Date(translateDate);
    newDate = newDate.valueOf();
    newDate = !along ? newDate - num * 24 * 60 * 60 * 1000 : newDate + num * 24 * 60 * 60 * 1000; // 备注 如果是往前计算日期则为减号 否则为加号
    newDate = new Date(newDate);
    // 如果月份长度少于2，则前加 0 补位
    if ((newDate.getMonth() + 1).toString().length == 1) {
        monthString = `${0}${(newDate.getMonth() + 1).toString()}`;
    } else {
        monthString = (newDate.getMonth() + 1).toString();
    }
    // 如果天数长度少于2，则前加 0 补位
    if (newDate.getDate().toString().length == 1) {
        dayString = `${0}${newDate.getDate().toString()}`;
    } else {
        dayString = newDate.getDate().toString();
    }
    dateString = `${newDate.getFullYear()}-${monthString}-${dayString}`;
    return dateString;
}

/**
 * 获取【'往前推N天的时间', '现在的时间'】
 * parmas s1 当前的时间 s2:List []
 */
function timeDefault(n: number) {
    let date = new Date();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let s1 = `${date.getFullYear()}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
    let s2 = [addByTransDate(s1, n), s1];
    return s2;
}

/**
 * 获取地址栏参数如果是多选类型拼接成数组
 * @param params
 */
function converArray(params: any) {
    let arr: any = [];
    if (!params) {
        return arr;
    }
    if (Object.prototype.toString.call(params) === '[object String]') {
        return [params];
    } else if (Object.prototype.toString.call(params) === '[object Array]') {
        return params;
    } else {
        return arr;
    }
}

export {
    project,
    deepClone,
    isAntDesignProOrDev,
    isAntDesignPro,
    isUrl,
    getPageQuery,
    setLoginInfo,
    success,
    error,
    warning,
    getBase64,
    handleRMB,
    addThousandth,
    randomString,
    columnFilter,
    handleStatusMap,
    copy,
    getImageBlob,
    exportExcel,
    timeFormat,
    timestampFormat,
    getJsonLength,
    isMobile,
    trim,
    isNumber,
    isEmptyObject,
    getSelectLabel,
    dateFormat,
    getCorrectDay,
    stripscript,
    setMobileStar,
    testOrderNum,
    getQueryVariable,
    getMerchantUrl,
    urlQueryToObj,
    listAddOrderNumber,
    exportExcelForUrl,
    timeDefault,
    converArray,
    setMobileEnd4,
};
