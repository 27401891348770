import { Effect } from 'dva';
import { Reducer } from 'redux';
export interface CurrentUser {
    avatar?: string;
    name?: string;
    title?: string;
    group?: string;
    signature?: string;
    tags?: {
        key: string;
        label: string;
    }[];
    unreadCount?: number;
}

export interface UserModelState {
    currentUser?: CurrentUser;
}

export interface UserModelType {
    namespace: 'user';
    state: UserModelState;
    reducers: {
        changeNotifyCount: Reducer<UserModelState>;
    };
}

const UserModel: UserModelType = {
    namespace: 'user',

    state: {
        currentUser: {},
    },
    reducers: {
        changeNotifyCount(
            state = {
                currentUser: {},
            },
            action,
        ) {
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    notifyCount: action.payload.totalCount,
                    unreadCount: action.payload.unreadCount,
                },
            };
        },
    },
};

export default UserModel;
